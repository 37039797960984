import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const PropertyManagers = () => {
    return (
        <Layout>
            <Seo
                title="Administradores De Fincas - BIKESAFE"
                description="Espacio para el parking de bicicletas, valor y seguridad son temas importantes para los administradores de fincas. Bike Safe ofrece sistemas de estacionamiento de bicicletas que cubren las necesidades de los administradores de fincas para todos los tipos de instalaciones. Tanto si desea dedicar una sala completa o un espacio más reducido para el parking de bicicletas, disponemos de la solución."
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                        Administradores De Fincas</h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <p className="flow-text">
                        Espacio para el parking de bicicletas, valor y seguridad son temas importantes para los administradores de fincas. Bike Safe ofrece sistemas de estacionamiento de bicicletas que cubren las necesidades de los administradores de fincas para todos los tipos de instalaciones. Tanto si desea dedicar una sala completa o un espacio más reducido para el parking de bicicletas, disponemos de la solución.
                        </p>
                        <p className="flow-text">
                        Le podemos ayudar a sacar el máximo provecho de su espacio para el estacionamiento de bicicletas gracias al diseño CAD de la superficie.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred"  src="../images/bristol-shelter.jpg" className="shadow mt-5 mb-5 fluid rounded" />
                        <p>
                            <em className="flow-text">"Muchas gracias por el excelente trabajo realizado con los portabicicletas. Tienen un aspecto magnífico y ya se están utilizando."
                                <strong> John Hilton, SMBC Group (Administradores de fincas), Londres</strong>.</em></p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default PropertyManagers;